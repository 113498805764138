import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';

import { OmitStylesComponentProps } from '../../types';

const avatarImageVariants = cva('rocco-avatar-image', {
    variants: {
        variant: {
            default: 'aspect-square h-full w-full',
        },
        fit: {
            cover: 'object-cover',
            contain: 'object-contain',
        },
    },
    defaultVariants: {
        variant: 'default',
        fit: 'contain',
    },
});

export const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    OmitStylesComponentProps<typeof AvatarPrimitive.Image> &
        VariantProps<typeof avatarImageVariants>
>(({ variant, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={avatarImageVariants({ variant })}
        {...props}
    />
));

AvatarImage.displayName = AvatarPrimitive.Image.displayName;
