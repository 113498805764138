import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';

import { OmitStylesComponentProps } from '../../types';

const avatarFallbackVariants = cva('rocco-avatar-fallback', {
    variants: {
        variant: {
            default:
                'flex h-full w-full items-center justify-center rounded-full bg-brick-500 text-white',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    OmitStylesComponentProps<typeof AvatarPrimitive.Fallback> &
        VariantProps<typeof avatarFallbackVariants>
>(({ variant, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={avatarFallbackVariants({ variant })}
        {...props}
    />
));

AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
