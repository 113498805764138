import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';

import { OmitStylesComponentProps } from '../../types';

const avatarVariants = cva(
    'rocco-avatar relative flex shrink-0 overflow-hidden rounded-full',
    {
        variants: {
            size: {
                square20: 'size-5',
                square24: 'size-6',
                square28: 'size-7',
                square32: 'size-8',
                square40: 'size-10',
                square64: 'size-16',
            },
            borderWidth: {
                none: 'border-none',
                small: 'border',
            },
            borderColor: {
                none: '',
                light: 'border-gray-200',
                dark: 'border-gray-900',
            },
        },
        defaultVariants: {
            size: 'square40',
            borderWidth: 'none',
            borderColor: 'none',
        },
    },
);

export const Avatar = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    OmitStylesComponentProps<typeof AvatarPrimitive.Root> &
        VariantProps<typeof avatarVariants>
>(({ size, borderWidth, borderColor, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={avatarVariants({ size, borderWidth, borderColor })}
        {...props}
    />
));

Avatar.displayName = AvatarPrimitive.Root.displayName;
